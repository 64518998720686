<template>
    <!-- 业务员审核订单 -->
    <div class="customer_box">

        <div class="customer_search_box">
            <div class="search-item">
                <span>关键字</span>
                <el-input style="width: 190px;" clearable placeholder="订单编号、商品名称" size="small" v-model="filterinfo.search"></el-input>
            </div>
            <div class="search-item">
                <span>下单日期</span>
                <el-date-picker style="width: 200px;" v-model="timeRange" type="daterange" size="small" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </div>

            <div class="btn_box">
                <el-button class="btn_item" type="primary" size="small" @click="getCustomerList(1)">查询</el-button>
                <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
            </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="多店订单" name="first"></el-tab-pane>
            <el-tab-pane label="单店订单" name="second"></el-tab-pane>
        </el-tabs>
        <div class="list_box" v-if="tableData.length&&activeName=='first'">
            <div class="list_item" v-for="(item,index) in tableData" :key="index">
                <div class="item_top" style="border:none;margin:0; ">
                    <div>
                        <el-checkbox v-model="item.checked" @change="storeChecked(item,$event)">多店订单编号:</el-checkbox>
                        <span> <span @click="viewMulDetail(item.mainOrderNumber)" style="color: #1a9dff;cursor: pointer;">{{item.mainOrderNumber}}</span></span>
                        <span class="copy" @click="copyOrder(item.mainOrderNumber)">复制</span>
                    </div>
                </div>

                <div class="item_center" style="align-items: center;height:90px;" v-for="(items,indexs) in item.ordersList" :key="indexs">
                    <div style="border-bottom: 1px solid #f7f7f7;align-items: center;height:90px;display:flex; justify-content: space-between;padding-bottom: 10px;width:100%;">
                        <div class="item_top" style="border:none;">
                            <div>
                                <div style="margin-bottom:5px;">
                                    <el-checkbox v-model="items.checked" @change="singleChecked(item,$event)">{{items.customerName}}</el-checkbox>
                                    <!-- <span style="margin-left:20px;">支付方式:</span>
                                <span style="margin-left:20px;" class="payType">微信支付</span>
                                <span style="margin-left:20px;" class="payType">支付宝支付</span> -->

                                </div>
                                <div style="margin-bottom:5px;"> <span>订单编号: <span style="color: #222;">{{items.orderNumber}}</span></span>
                                    <span class="copy" @click="copyOrder(items.orderNumber)">复制</span>
                                </div>
                                <div style="margin-bottom:5px;">
                                    <div style="display:flex;align-items:center;flex:1;text-align:center;">
                                        <div style="font-size: 16px;margin-right:20px;"><span style="vertical-align: middle;">商品总件数：</span><span style="color:#F92B2B;font-weight: 600;vertical-align: middle;">{{items.kindNum }}</span></div>
                                        <div style="font-size: 16px;margin-right:20px;">
                                            <span style="vertical-align: middle;">优惠金额：</span><span style="font-weight: 600;color:#F92B2B;vertical-align: middle;">￥{{items.preferPrice.toFixed(2)}}</span>
                                        </div>
                                        <div style="font-size: 16px;"><span style="vertical-align: middle;">应付金额：</span><span style="font-size: 16px;color: #F92B2B;font-weight: 600;vertical-align: middle;">￥{{items.realPrice.toFixed(2)}}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item_right_box" style="height:90px;">
                            <div class="item_right">
                                <span>{{items.status }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item_bottom">
                    <div>
                        共<span style="color: #F92B2B;font-weight:600;">{{item.total}}</span>
                        件商品<span style="display: inline-block;width: 12px;"></span>
                        合计：<span style="color: #F92B2B;">￥
                            <span style="font-size: 20px;font-weight:600;">{{item.realPrice.toFixed(2)}}</span>
                            <!-- <span v-if="tabIndex==6" style="font-size: 20px;font-weight:600;">{{item.price}}</span> -->
                        </span>
                        优惠：<span style="color: #F92B2B;">￥
                            <span style="font-size: 20px;font-weight:600;">{{item.preferPrice.toFixed(2)}}</span>
                            <!-- <span v-if="tabIndex==6" style="font-size: 20px;font-weight:600;">{{item.price}}</span> -->
                        </span>
                        <!-- <span v-if="item.extra">，包含运费{{item.extra}}元</span> -->
                    </div>
                    <div class="btn_box">
                        <!-- <div class="btn_item"  @click="exportOrder(items.orderNumber)">导出Excel</div>
                        <div class="btn_item" v-if="(items.status=='待收货'||items.status=='已完成')" @click="invoiceClick(items.orderNumber)">查看发票</div> -->
                        <div class="btn_item" @click="againBuyMultiple(item)">再次购买</div>
                        <!-- <div class="btn_item" v-if="(items.status=='待收货'||items.status=='已完成')" @click="deliveryClick(items.orderNumber)">查看物流</div>
                        <div class="btn_item" v-if="(items.status=='待收货'||items.status=='已完成')" @click="outClick(items.orderNumber)">出库发货单</div>

                        <el-popconfirm title="为了保证您的售后权益，请收到商品确认无误后再确定收货" @onConfirm="suerClick(items.orderNumber)" v-if="items.status!='已完成'&&items.status=='待收货'">
                            <div class="btn_item" slot="reference" v-if="items.status!='已完成'&&items.status=='待收货'">确认收货</div>
                           
                        </el-popconfirm> -->
                        <!-- 
                        <el-popconfirm title="取消后无法恢复，优惠券可退回，有效期内使用" @onConfirm="cancelCilckMultiple(item)">
                            <div class="btn_item" slot="reference">取消订单</div>

                        </el-popconfirm>
                        <div class="btn_item active" @click="goPayMultiple(item)">去支付</div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="list_box" v-else-if="tableData.length&&activeName=='second'">
            <div class="list_item" v-for="(item,index) in tableData" :key="index">
                <div class="item_top">
                    <div>
                        <span>订单编号: <span style="color: #222;">{{item.orderNumber}}</span></span>
                        <span class="copy" @click="copyOrder(item.orderNumber)">复制</span>
                    </div>
                    <span>下单时间: <span style="color: #222;">{{item.postDate}}</span></span>
                </div>
                <div class="item_center">
                    <div class="goods_box">
                        <div class="goods_list">
                            <img class="goods_item" v-for="(items,index) in item.imgs" :key="index" :src="items" alt="">
                        </div>
                    </div>
                    <div class="item_right_box">
                        <div class="item_right">
                            <span>{{item.status}}</span>
                            <span @click="viewDetail(item.orderNumber)">查看订单详情</span>
                        </div>
                    </div>
                </div>
                <div class="item_bottom">
                    <div>共<span style="color: #F92B2B;font-weight:600;">{{item.goodsNum}}</span>件商品<span style="display: inline-block;width: 12px;"></span>合计：<span style="color: #F92B2B;">￥<span style="font-size: 20px;font-weight:600;">{{item.paid}}</span>，</span> 包含运费{{item.extra}}元</div>
                    <div class="btn_box">
                        <div class="btn_item" @click="exportOrder(item.orderNumber)">导出Excel</div>
                        <div class="btn_item" @click="againBuy(item.orderNumber)">再次购买</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div slot="empty" class="nodata_box">
                <img src="@/assets/images/index/nodata.png" />
                <span class="txt">暂无数据哦~</span>
            </div>
        </div>
        <div class="pagination_box" v-if="tableData.length">
            <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getCustomerList" @size-change="getCustomerList(1)">
            </el-pagination>
        </div>
        <priceDialog v-if="tipVisible" @tipClose="tipClose" @closePriceDialog="noBuy" :priceList="tipList" :tipVisible="tipVisible"></priceDialog>
    </div>
</template>

<script>
import { getCarNum } from '@/utils/carNumber'
import priceDialog from '@/views/shopCar/component/priceDialog.vue'
export default {
    name: 'AuditingOrder',
    components: {
        priceDialog
    },
    data() {
        return {
            loading: false,
            activeName: 'first',
            total: 0,
            timeRange: [],
            filterinfo: {
                page: 1,
                pageSize: 10,
                search: '',
                timeRange: '',
            },
            tableData: [],
            tipList: [],//
            tipVisible: false,
        };
    },
    created() {
         if(this.$store.state.memberInfo.isMain == 1){
            this.activeName='first'
         }else{
            this.activeName='second'
         }
    },
    mounted() {
        this.getCustomerList()
    },
    methods: {
        handleClick(tab, event) {
            // console.log(tab, event);
            this.resetClick()
        },
        storeChecked(item, val) {
            console.log(item, val);

            item.ordersList.forEach(item => {
                item.checked = val
            })

        },
        tipClose() {
            this.tipVisible = false
        },
        noBuy() {
            this.tipVisible = false
        },
        singleChecked(item, val) {
            item.checked = item.ordersList.some(item => {
                return item.checked
            })




        },
        // 点击重置
        resetClick() {
            this.filterinfo = {
                page: 1,
                pageSize: 10,
                search: '',
                timeRange: '',
            }
            this.timeRange = []
            console.log(this.activeName);
            // if(this.activeName=='first'){
            //   this.getMultipleList()
            // }else{
            //   this.getCustomerList()
            // }
            this.getCustomerList()
        },
        copyOrder(text) {

            if (navigator.clipboard && window.isSecureContext) {
                // navigator clipboard 向剪贴板写文本
                navigator.clipboard.writeText(text);
                return this.$message.success('复制成功')
            } else {
                // document.execCommand('copy') 向剪贴板写文本
                let input = document.createElement('input')
                input.style.position = 'fixed'
                input.style.top = '-10000px'
                input.style.zIndex = '-999'
                document.body.appendChild(input)
                input.value = text
                input.focus()
                input.select()
                try {
                    let result = document.execCommand('copy')
                    document.body.removeChild(input)
                    if (!result || result === 'unsuccessful') {
                        this.$message.warning('复制失败')
                    } else {
                        this.$message.success('复制成功')
                    }
                } catch (e) {
                    document.body.removeChild(input)
                    alert('当前浏览器不支持复制功能，请检查更新或更换其他浏览器操作')
                }
            }


        },
        exportOrder(orderNumber) {
            this.$api.orderExport({ orderNumber }).then((res) => {
                //  if (res.status==200){
                //         // this.isLoading=false

                //     }
            });
        },
        async againBuy(orderNumber) {
            const res = await this.$api.buyAgain({ orderNumber })
            if (res.data.code == 200) {
                if (res.data.data.needCheck) {
                    this.tipList = res.data.data.needSureGoods
                    this.tipVisible = true
                }
                this.$message.success('已将订单商品加入购物车')
                getCarNum(this)
            }
        },
        // 获取列表
        getCustomerList(num) {
            if (num == 1) {
                this.filterinfo.page = 1
            }
            let params = this.filterinfo
            if (this.timeRange && this.timeRange.length) {
                params.timeRange = this.timeRange[0] + '/' + this.timeRange[1]
            } else {
                params.timeRange = ''
            }
            this.loading = true
            if (this.activeName == 'first') {
                this.$api.multipleOrderWait(params).then((res) => {
                    this.loading = false
                    if (res.data.code === 200) {
                        res.data.data.list = res.data.data.list ? res.data.data.list : []
                        res.data.data.list.forEach(item => {
                            item.checked = false
                            item.total = item.ordersList.reduce((a, b) => {
                                return a + b.kindNum 
                            }, 0)
                            item.realPrice = item.ordersList.reduce((a, b) => {
                                return a + b.realPrice
                            }, 0)
                            item.preferPrice = item.ordersList.reduce((a, b) => {
                                return a + b.preferPrice
                            }, 0)
                            item.ordersList.forEach(el => {
                                el.checked = false
                            })
                        })
                        this.tableData = res.data.data.list
                        this.total = res.data.data.total
                    }
                });
            } else {
                this.$api.waitOrderList(params).then((res) => {
                    this.loading = false
                    if (res.data.code === 200) {
                        this.tableData = res.data.data.list ? res.data.data.list : []
                        this.total = res.data.data.total
                    }
                });
            }
        },
        viewDetail(orderNumber) {
            this.$router.push({ 'name': 'OrderPersDetail', query: { orderNumber, from: 'wait' } })
        },
        viewMulDetail(mainOrderNumber) {
            this.$router.push({ 'name': 'OrderPersDetail', query: { mainOrderNumber, from: 'wait' } })
        },
        async againBuyMultiple(item) {
            console.log(item);
            if (!item.checked) return this.$message.warning('请选择要购买的订单')
            const ordersList = item.ordersList.filter(item => item.checked)
            const orderNumber = ordersList.map(item => item.orderNumber)
            const res = await this.$api.multipleOrderAgain({ mainOrderNumber: item.mainOrderNumber, orderNumber })
            // if (res.data.code == 200) {
            //     this.$message.success('取消订单成功')
            //      this.getMultipleList()
            // }
            if (res.data.code == 200) {
                if (res.data.data.notice && res.data.data.notice.length) {
                    this.$notify({
                        title: '提示',
                        message: res.data.data.notice.join('\n') + '，其余加入购物车成功',
                        type: 'warning'
                    });
                } else {
                    this.$message.success('已将订单商品加入购物车')
                }
                // this.$message.success('已将订单商品加入购物车')
                getCarNum(this)
            }
        },
        async cancelCilckMultiple(item) {
            console.log(item);
            const ordersList = item.ordersList.filter(item => item.checked)
            if (!ordersList.length) return this.$message.warning('请选择要取消的订单')
            const orderNumber = ordersList.map(item => item.orderNumber)
            const res = await this.$api.multipleOrderCancel({ mainOrderNumber: item.mainOrderNumber, orderNumber })
            if (res.data.code == 200) {
                this.$message.success('取消订单成功')
                this.getMultipleList()
            }
        },
    }
};
</script>
<style lang="scss" scoped>
.customer_box {
    position: relative;
    padding-top: 8px;

    .list_box {
        .list_item {
            margin-bottom: 14px;
            background: #fff;

            & > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .item_top {
                height: 40px;
                padding: 0 31px 0 17px;
                margin-bottom: 15px;
                border-bottom: 1px solid #eeeeee;
                font-size: 14px;
                color: #666;

                .copy {
                    font-size: 13px;
                    color: #0687fe;

                    &:hover {
                        cursor: pointer;
                        color: #337cff;
                    }

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 10px;
                        margin: 0 4px 0 7px;
                        background: #d8d8d8;
                    }
                }
            }
            .item_center {
                align-items: flex-start;
                padding-left: 16px;
                /*滚动条整体部分*/
                ::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                }
                /*滚动条的轨道*/
                ::-webkit-scrollbar-track {
                    background-color: #f9f9f9;
                }
                /*滚动条里面的小方块，能向上向下移动*/
                ::-webkit-scrollbar-thumb {
                    background-color: #e9e9e9;
                    border-radius: 7px;
                }
                ::-webkit-scrollbar-thumb:hover {
                    background-color: #d9d9d9;
                }
                ::-webkit-scrollbar-thumb:active {
                    background-color: #c9c9c9;
                }
                .goods_box {
                    flex: 1;
                    overflow: auto;
                    padding-bottom: 8px;

                    .goods_list {
                        display: flex;
                        .goods_item {
                            width: 100px;
                            height: 100px;
                            margin-right: 10px;
                        }
                    }
                }
                .item_right_box {
                    overflow: hidden;
                    width: 150px;
                    height: 100px;
                    display: flex;
                    justify-content: flex-end;

                    .item_right {
                        width: 140px;
                        height: 100px;
                        flex-shrink: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        box-shadow: -3px 0px 6px 1px rgba(188, 188, 188, 0.16);

                        span:first-of-type {
                            margin-bottom: 8px;
                            margin-right: 10px;
                            font-weight: bold;
                            font-size: 16px;
                            color: #333333;
                        }
                        span:last-of-type {
                            font-size: 14px;
                            color: #0687fe;
                            margin-right: 10px;

                            &:hover {
                                color: #337cff;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .item_bottom {
                padding: 10px 31px 15px 16px;
                font-size: 14px;
                color: #666666;

                .btn_box {
                    display: flex;
                    align-items: center;

                    .btn_item {
                        width: 86px;
                        height: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 10px;
                        border-radius: 100px 100px 100px 100px;
                        border: 1px solid #cccccc;
                        font-size: 16px;
                        color: #111111;
                        cursor: pointer;

                        &:hover {
                            border-color: #e3e3e3;
                            color: #666666;
                        }
                    }
                }
            }
        }
    }
}
</style>