// 获取购物车数量
export function getCarNum(vm) {
	vm.$api.pcCarNum().then((res) => {
		if (res.data.code === 200) {
			vm.$store.commit('setCarNumber', res.data.data.num);
		}
	});
}
// 获取订单数量
export function getOrderNum(vm) {
	let params={
		prepareOrderNumber: vm.$route.query.prepareOrderNumber
	}
	vm.$api.pcPrepareOrderNum(params).then((res) => {
		if (res.data.code === 200) {
			vm.$store.commit('setOrderNumber', res.data.data.num);
		}
	});
}