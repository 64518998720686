<template>
    <div>
        <el-dialog class="tipDialog" :visible.sync="tipVisible"  width="20%" @close="tipClose" :show-close="false">
            <div style="font-weight: bold;font-size: 16px;color: #333333;text-align: center;">提示</div>
            <div style="font-size: 16px;color: #333333;margin-top: 15px;">加购的部分商品由于公司价格调整，<span style="color:#F92B2B;">当
                    前不允许购买</span> ，请前往商品详情解决</div>
            <div class="tip_content" style="font-size: 12px;margin-top: 8px;color: #F92B2B;min-height: 200px;max-height: 200px;overflow: auto;">
                <div v-for="(item,index) in priceList" :key="index" style="display: flex;justify-content: space-between;font-size: 14px;">
                    <div style="color: #666666;">{{ index+1 }}.{{ item.productName }}</div>
                    <div @click="GoodsDetail(item.goodsId)" style="color: #0687FE;display: flex;align-items: center;cursor: pointer;"><span>去看看</span> <img style="height: 12px;width: 12px;" src="../../../assets/images/shopCar/goleft.svg" alt=""></div>
                </div>
            </div>
            <div @click="noBuy" style="font-weight: bold;margin-top: 22px;padding: 12px 0 0;font-size: 16px;color:#333333;text-align: center;border-top: 1px solid #E0E0E0;cursor: pointer;">先不买了</div>

        </el-dialog>
    </div>

</template>

<script>
export default {
    props: {
        tipVisible: {
            type: Boolean,
            default: false
        },
        priceList: {
            type: Array,
            default: []
        },

    },
    data() {
        return {
           

        }
    },
    mounted() {
       
       
    },
    methods: {
        GoodsDetail(id){
            this.$router.push('/goodsDetail?goodsId=' + id)
        },
    
        tipClose() {
            this.$emit('tipClose',false)
        },
        noBuy() {
            this.$emit('closePriceDialog',false)
        },
       
    }
}
</script>

<style lang="scss" scoped>
.subject {
    border-top: 1px solid #f2f2f2;
    padding: 5px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.address {
    padding: 12px 0;
    border-bottom: 1px dashed #e6e6e6;
    width: 100%;
    display: flex;
}
.my-dialog {
    .dialog-body {
        // display: flex;
        // // justify-content: center;
        // flex-direction: column;
        // align-items: center;

        font-size: 14px;
        color: #a0a0a0;
        img {
            width: 172px;
            height: 172px;
        }
    }
}

.you-dialog {
    .dialog-body {
        // display: flex;
        // // justify-content: center;
        // flex-direction: column;
        // align-items: center;

        font-size: 14px;
        color: #a0a0a0;
        img {
            width: 172px;
            height: 172px;
        }
    }
}
.upload-demo {
    width: 80px;
    height: 80px;
    background: #f3f5f5;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: #ebeeee;
    }
}
.btn {
    &:hover {
        background: #0687fe !important;
    }
}
.imgList {
    position: relative;
    i {
        position: absolute;
        top: 2px;
        right: 1px;
        color: #c2c4c0;
        cursor: pointer;
    }
}
.first {
    margin-top: -4px;
}
</style>
<style lang="scss" >
.tipDialog .el-dialog__header {
    padding: 0 !important;
}
.tipDialog .el-dialog {
    border-radius: 16px 16px 16px 16px;
    width: 316px !important;
}
.tipDialog .el-dialog__body {
    padding: 20px 15px 12px;
}
.priceBtn {
    // width: 279px;
    height: 42px;
    border: 1px solid #e6e6e6;
    text-align: center;
    line-height: 42px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 12px;
    font-size: 14px;
    color: #111111;
}
.selectBtn {
    background: #0687fe;
    color: #fff;
    .contact {
        color: #fff !important;
    }
}
.tip_content {
    &::-webkit-scrollbar-thumb {
        background-color: #ebeaef;

        border-radius: 10px;
    }

    /*滚动条整体样式*/

    &::-webkit-scrollbar {
        width: 6px;
        height: 35px;
    }

    /*滚动条里面轨道*/

 &::-webkit-scrollbar-track {
        background: #ffffff;

        border-radius: 10px;
    }
}
</style>